<template>
  <div class="row">
    <div class="col-md-12">
      <KTCodePreview v-bind:title="'Form Add User Role'">
        <template v-slot:preview>
          <b-row>
            <b-col md="12">
              <b-form class="form" @submit.stop.prevent="onSubmit">
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="nama_role_label"
                  label="Nama Role :"
                  label-for="nama_role"
                >
                  <b-form-input
                    id="nama_role"
                    v-model="$v.form.nama_role.$model"
                    type="text"
                    placeholder="Enter Text..."
                    :state="validateState('nama_role')"
                  ></b-form-input>

                  <b-form-invalid-feedback id="nama_role_feedback">
                    Nama Role Harus Terisi.
                  </b-form-invalid-feedback>
                </b-form-group>
                <hr />
                <h3>Setting Fitur</h3>
                <b-row class="mb-2">
                  <b-col>
                    <treeselect
                      :multiple="true"
                      :clearable="true"
                      :options="fitur"
                      placeholder="Select Fitur..."
                      v-model="$v.form.fitur.$model"
                      :default-expand-level="1"
                      sort-value-by="INDEX"
                      :state="validateState('fitur')"
                    />

                    <b-form-invalid-feedback id="fitur_feedback">
                      Fitur Harus Terisi.
                    </b-form-invalid-feedback>
                  </b-col>
                </b-row>

                <b-button
                  variant="outline-dark"
                  :disabled="loading"
                  @click="$router.push('/user/role')"
                >
                  <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                  <span v-else class="svg-icon svg-icon-sm svg-icon-dark">
                    <i class="flaticon2-left-arrow-1"></i>
                  </span>
                  Discard
                </b-button>

                <b-button class="ml-3" type="submit" variant="outline-success" :disabled="loading">
                  <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                  <span v-else class="svg-icon svg-icon-sm svg-icon-success">
                    <i class="flaticon2-check-mark"></i>
                  </span>
                  Submit
                </b-button>
              </b-form>
            </b-col>
          </b-row>
        </template>
      </KTCodePreview>
    </div>
  </div>
</template>
<style lang="scss">
@import '~vuetify/dist/vuetify.css';

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { mapState } from 'vuex'

import ApiService from '@/core/services/api.service'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  mixins: [validationMixin],
  name: 'addrole',
  data() {
    return {
      form: {
        nama_role: null,
        fitur: [],
      },

      fitur: [
        {
          id: 'Home',
          label: 'Home',
        },
        {
          id: 'Dashboard',
          label: 'Dashboard',
        },
        {
          id: 'Report',
          label: 'Data Inspeksi',
          children: [
            {
              id: 'R Create Report',
              label: 'Create Report',
            },
            {
              id: 'R View Report Only',
              label: 'View Report Only',
            },
            {
              id: 'R Conditional Report',
              label: 'Conditional Report',
            },
          ],
        },
        {
          id: 'Data Desain',
          label: 'Data Desain',
        },
        {
          id: 'Data Desain View Only',
          label: 'Data Desain View Only',
        },
        {
          id: 'Inspection Plan',
          label: 'Inspection Plan',
        },
        {
          id: 'To Do',
          label: 'To Do',
        },
        {
          id: 'Inspection Plan View Only',
          label: 'Inspection Plan View Only',
        },
        {
          id: 'Penilaian Resiko',
          label: 'Penilaian Resiko',
        },
        {
          id: 'Peralatan Inspeksi',
          label: 'Peralatan Inspeksi',
        },
        {
          id: 'Peralatan Inspeksi View Only',
          label: 'Peralatan Inspeksi View Only',
        },
        {
          id: 'Peralatan Inspeksi Peminjam',
          label: 'Peralatan Inspeksi Peminjam',
        },
        {
          id: 'Sertifikasi Perizinan',
          label: 'Sertifikasi Perizinan',
        },
        {
          id: 'Sertifikasi Perizinan View Only',
          label: 'Sertifikasi Perizinan View Only',
        },
        {
          id: 'Pengujian & Kalibrasi',
          label: 'Pengujian & Kalibrasi',
        },
        {
          id: 'Kualifikasi Las',
          label: 'Kualifikasi Las',
          children: [
            {
              id: 'Welder',
              label: 'Welder',
            },
            {
              id: 'Kualifikasi Welder',
              label: 'Kualifikasi Welder',
            },
            {
              id: 'Pengelasan History',
              label: 'History',
            },
            {
              id: 'PQR',
              label: 'PQR',
            },
            {
              id: 'WPS',
              label: 'WPS',
            },
          ],
        },
        {
          id: 'Master',
          label: 'Master',
          children: [
            {
              id: 'M Pabrik',
              label: 'Pabrik',
            },
            {
              id: 'M Eksekutor',
              label: 'Eksekutor',
            },
            {
              id: 'M Plant',
              label: 'Plant',
            },
            {
              id: 'M Part',
              label: 'Part',
            },
            {
              id: 'M Equipment',
              label: 'Equipment',
            },
            {
              id: 'M Condition Monitoring',
              label: 'Condition Monitoring',
            },
            {
              id: 'M Group Point',
              label: 'Group Point',
            },
            {
              id: 'M Point',
              label: 'Point',
            },
            // {
            //   id: "M Inspection Element",
            //   label: "Inspection Element",
            // },
          ],
        },
        {
          id: 'Setting User',
          label: 'Setting User',
          children: [
            {
              id: 'Role',
              label: 'Role Report',
            },
            {
              id: 'Users',
              label: 'Users',
            },
          ],
        },
        {
          id: 'Google Drive',
          label: 'Google Drive',
        },
      ],

      loading: false,
    }
  },
  validations: {
    form: {
      nama_role: {
        required,
      },
      fitur: {
        required,
      },
    },
  },
  components: {
    KTCodePreview,
    Treeselect,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'User', route: '/user/role' },
      { title: 'Create Role' },
    ])
  },
  watch: {
    selected(newValue) {
      // console.log(oldValue);
      // Handle changes in individual flavour checkboxes
      if (newValue.length === 0) {
        this.indeterminate = false
        this.allSelected = false
      } else if (newValue.length === this.flavours.length) {
        this.indeterminate = false
        this.allSelected = true
      } else {
        this.indeterminate = true
        this.allSelected = false
      }
    },
  },
  methods: {
    toggleAll(checked) {
      this.selected = checked ? this.flavours.slice() : []
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    onSubmit() {
      // event.preventDefault()
      this.$v.form.$touch()
      if (this.$v.form.$anyError) {
        return
      }
      var self = this
      self.loading = true
      const nama_role = self.$v.form.nama_role.$model
      const fitur = self.$v.form.fitur.$model

      ApiService.post('/user/role/store', {
        nama_role: nama_role,
        fitur: fitur,
      })
        .then(({ data }) => {
          if (data.status == 'ok') {
            self.makeToast('success', 'Data berhasil disimpan')
            self.$router.push('/user/role')
          }
        })
        .catch(response => {
          console.log(response)
        })
    },
    timeout(ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
    makeToast(variant = null, $content) {
      this.$bvToast.toast($content, {
        title: `${variant || 'default'}`,
        variant: variant,
        toaster: 'b-toaster-top-center',
        solid: true,
      })
    },
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors,
    }),
  },
}
</script>
